import { ChainId, Currency, currencyEquals, ETHER, Token } from '@gizzard-dex/sdk'
import { Text } from 'packages/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'

// import { isMobile } from 'react-device-detect'
import { SUGGESTED_BASES } from '../../config/constants'
import { AutoColumn } from '../Layout/Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow } from '../Layout/Row'
import { CurrencyLogo } from '../Logo'

const BaseWrapper = styled.div<{ disable?: boolean }>`
  /* border: 1px solid #d9d9d9; */
  border-radius: 8px;
  display: flex;
  padding: 4px;
  color: black;
  align-items: center;

  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: #d9d9d9;
    color: black;
  }
  background-color: ${({ theme, disable }) => disable && theme.colors.dropdown};
  opacity: ${({ disable }) => disable && '0.4'};
`

const StyledText = styled(Text).attrs({ fontSize: ['10px', , , , '14px'] })`
  color: black;
  font-weight: 500;
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const { t } = useTranslation()
  return (
    <AutoColumn gap="md">
      <AutoRow>
        <Text color="black" fontSize={['14px', , , , '16px']}>
          {t('Common bases')}
        </Text>
        <QuestionHelper text={t('These tokens are commonly paired with other tokens.')} ml="4px" />
      </AutoRow>
      <AutoRow
        borderRadius="10px"
        background="rgba(217, 217, 217, 1)"
        padding="4px 6px"
        height="45px"
        style={{ gap: '5px', marginBottom: '8px' }}
      >
        <BaseWrapper
          onClick={() => {
            if (!selectedCurrency || !currencyEquals(selectedCurrency, ETHER)) {
              onSelect(ETHER)
            }
          }}
          disable={selectedCurrency === ETHER}
        >
          <CurrencyLogo currency={ETHER} style={{ borderRadius: '50%' }} />
          {/* <StyledText>{ETHER.symbol}</StyledText> */}
        </BaseWrapper>
        {(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {
          const selected = selectedCurrency instanceof Token && selectedCurrency.address === token.address
          return (
            <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected} key={token.address}>
              <CurrencyLogo currency={token} style={{ borderRadius: '50%' }} />
              {/* <StyledText>{token.symbol}</StyledText> */}
            </BaseWrapper>
          )
        })}
      </AutoRow>
    </AutoColumn>
  )
}
